figure.image {
    display: block;

    picture {
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}
