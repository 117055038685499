.tile--testimonial {
    background: var(--color--grey);
    padding: var(--spacing--md);
    text-align: center;

    .paragraph {
        position: relative;
        padding-top: var(--spacing--xs);

        &::before {
            --width: 30%;
            content: '';
            width: var(--width);
            height: 1px;
            position: absolute;
            top: 0;
            left: calc(50% - var(--width) * 0.5);
            background: var(--color--font-base);
        }
    }
}
