div.image--withCitation {
    position: relative;

    @media (--media--md) {
        div.tile--testimonial {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            background: transparent !important;

            .fc--light {
                color: var(--color--light);
            }
        }
    }

    div.tile--testimonial {
        div.paragraph {
            &:first-child {
                font-size: var(--headline--md);
                line-height: var(--headline--md-height);
            }
        }

        .fc--light {
            color: var(--color--font-base);
        }
    }
}
