form {
    grid-area: large;

    fieldset {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        gap: calc(var(--spacing--column) * 0.5);

        @media (--media--md) {
            grid-template-columns: repeat(2, 1fr);

            h2, p, .checkbox-input {
                grid-column-start: span 2;
            }

            .w100, fieldset {
                grid-column-end: span 2;
            }
        }

        h2 {
            width: 100%;
            font-size: var(--text--base);
        }

        .clearfix {
            display: block;
            width: 100%;

            select, label {
                display: none;
            }

            input,
            textarea {
                display: block;
                width: 100%;
                font-family: var(--font--base-family);
                font-size: var(--text--base);
                line-height: var(--text--base-height);
                color: var(--color--font-base);
                background: var(--color--grey);
                outline: none;
                padding: var(--spacing--sm);
                border: 2px solid transparent;
                border-radius: 0;

                &::placeholder,
                &::-ms-input-placeholder,
                &::-ms-input-placeholder  {
                    color: rgba(126, 126, 126, 0.25);
                }
                &:focus {
                    border-color: var(--color--font-base);
                }
                &.add-on {width: auto;}
            }

            textarea {
                height: 250px;
                resize: none;
            }

            .selectContainer {
                position: relative;

                .currentOption {
                    position: relative;
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    color: var(--color--font-base);
                    background: var(--color--grey);
                    padding: var(--spacing--sm);
                    border: 2px solid transparent;
                    cursor: pointer;
                    transition: all 0.35s var(--easing--in-out);

                    &::after {
                        content: url(../Images/arrow-down.svg);
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 50%;
                        right: var(--spacing--sm);
                        transform: translateY(-50%) rotate(0deg);
                        transition: transform 0.35s var(--easing--in-out);
                    }
                }

                .selectContainerInner {
                    opacity: 0;
                    width: 100%;
                    position: absolute;
                    top: 100%;
                    left: -9999px;
                    z-index: 110;
                    background: var(--color--font-base);
                    transition: opacity 0.35s var(--easing--in-out), left 0.01s 0.375s;

                    .selectOption {
                        font-size: var(--text--base);
                        line-height: var(--text--base-height);
                        color: var(--color--light);
                        padding: 0.75rem var(--spacing--sm);
                        cursor: pointer;

                        &:hover, &:focus {
                            background: var(--color--primary);
                        }
                    }
                }

                &.active {
                    .currentOption {
                        &::after {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }

                    .selectContainerInner {
                        opacity: 1;
                        left: 0;
                        transition: left 0.01s, opacity 0.35s 0.1s var(--easing--in-out);
                    }
                }
            }

            &.checkbox-input {
                display: flex;
                align-items: center;
                width: 100%;

                label {
                    display: block;
                    order: 2;
                    font-size: 1.05rem;
                    line-height: 1.5;
                    color: var(--color--font-base);
                    padding-left: 5px;
                    margin-bottom: 0.25rem;
                }

                input[type="checkbox"] {
                    position: relative;
                    padding: 0;
                    margin-right: 0.5rem;
                    cursor: pointer;

                    &::before {
                        content: '';
                        display: block;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 5;
                        background: var(--color--light);
                        border: 1px solid var(--color--font-base);
                        transform: translate(-50%, -50%);
                    }

                    &:checked {
                        &::before {
                            content: '✓';
                            line-height: 1;
                            color: var(--color--light);
                            background: var(--color--primary);
                            border-color: var(--color--primary);
                            text-align: center;
                        }
                    }
                }
            }

            &.error {
                .input {
                    display: flex;
                    flex-direction: column;

                    .selectContainer {
                        order: -1;
                    }
                }
                .help-inline {
                    font-size: var(--text--xs);
                    font-weight: 700;
                    text-transform: uppercase;
                    color: rgb(156, 10, 7);
                    margin-top: calc(var(--spacing--xs) * 0.5);
                }
                .currentOption, input, textarea {
                    color: rgb(156, 10, 7);
                    border: 2px solid rgb(156, 10, 7);
                }
            }
        }
    }

    nav.form-navigation {

        ul {
            padding-top: calc(var(--spacing--md) * 1.75);

            li.submit {
                list-style: none;

                button {
                    display: inline-block;
                    position: relative;
                    font-size: var(--text--base);
                    font-weight: 700;
                    letter-spacing: 3px;
                    text-transform: uppercase;
                    color: var(--color--light);
                    background: var(--color--primary);
                    padding: 0.75rem 1rem;
                    border: 2px solid var(--color--primary);
                    cursor: pointer;
                    transition: all 0.35s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            color: var(--color--primary);
                            background: var(--color--light);
                        }
                    }
                }
            }
        }
    }
}
