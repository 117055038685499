.tile--icon {
    position: relative;
    background: var(--color--grey);
    padding: var(--spacing--md);
    padding-top: calc(var(--spacing--md) * 1.75);
    margin-top: calc(var(--spacing--md) * 1.55);

    div.icon--container {
        width: var(--spacing--lg);
        height: var(--spacing--lg);
        position: absolute;
        top: 0;
        background: var(--color--font-base);
        border-radius: 50%;
        transform: translateY(-65%);

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
