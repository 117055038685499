footer.footer {
    padding-top: var(--spacing--md);
    padding-bottom: var(--spacing--lg);

    div.footer--container {
        display: grid;
        grid-template-columns:  [text-start navigation-start social-start]
                                    1fr
                                [social-end navigation-end text-end];
        grid-template-rows: auto;
        grid-gap: var(--spacing--column);

        @media (--media--md) {
            grid-template-columns:  [text-start navigation-start]
                                        1fr
                                    [navigation-end text-end social-start]
                                        0.5fr
                                    [social-end];
        }
        @media (--media--lg) {
            grid-template-columns:  [text-start]
                                        1.5fr
                                    [text-end navigation-start]
                                        2fr
                                    [navigation-end social-start]
                                        0.5fr
                                    [social-end];
        }
    }

    div.paragraph {
        grid-area: text;
        grid-row-start: 1;
        grid-row-end: span 1;
        margin-bottom: var(--spacing--md);

        a {
            color: var(--color--light);

            &::before {
                background: var(--color--light);
            }
        }
    }

    nav.navigation--footer {
        grid-area: navigation;
        grid-row-start: 2;
        grid-row-end: span 1;
        margin-bottom: var(--spacing--md);

        @media (--media--lg) {
            grid-row-start: 1;
        }
    }

    div.social--media {
        display: flex;
        grid-area: social;
        grid-row-start: 3;
        grid-row-end: span 1;
        justify-content: flex-start;
        align-items: center;
        height: fit-content;
        margin-bottom: var(--spacing--md);
        gap: var(--spacing--xs);

        @media (--media--md) {
            grid-row-start: 1;
        }

        a {
            svg {
                display: block;
                width: auto;
                height: var(--spacing--md);
                transition: fill 0.35s var(--easing--in-out);
                fill: var(--color--light);
            }

            span.sr-only {
                width: 0;
                height: 0;
                position: absolute;
                overflow: hidden;
            }
        }
    }
}
