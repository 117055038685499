a.button {
    display: block;
    width: fit-content;
    position: relative;
    font-weight: 700;
    color: var(--color--primary);
    padding: 0.5rem 0;
    padding-left: 1.25rem;
    margin-right: var(--spacing--sm);
    transition: all 0.35s var(--easing--in-out);

    @media (hover: hover) {
        &:hover {
            color: var(--color--primary);
            padding-left: 1.5rem;

            &::before {
                background: var(--color--primary);
                transform: translateY(-50%) rotate(0) translateX(25%);
            }
        }
    }

    &::before {
        content: '';
        display: block;
        width: calc(var(--spacing--xs) * 0.75);
        height: calc(var(--spacing--xs) * 0.75);
        position: absolute;
        top: var(--text--base);
        left: 0;
        z-index: 1;
        background: var(--color--primary);
        transform: translateY(-50%) rotate(-9deg);
        transition: all 0.35s var(--easing--in-out);
    }
}
