div.group {
    div.group--inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--spacing--column);
    }

    .tile--icon, .tile--testimonial {
        --el--column-count: 1;
        --gap-count: calc(var(--el--column-count) - 1);
        width: calc(100% / var(--el--column-count) - (var(--gap-count) / var(--el--column-count) * var(--spacing--lg)));

        @media (--media--md) {
            --el--column-count: 2;
        }
        @media (--media--xl) {
            --el--column-count: 3;
        }
    }
}
