div.hero--banner {
    height: 60vh;
    max-height: 600px;
    min-height: 350px;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
