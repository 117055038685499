nav.navigation--header {
    height: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--color--font-base);
    overflow: hidden;
    transition: all 0.5s var(--easing--out);

    @media (--media--lg) {
        display: flex;
        align-items: stretch;
        height: initial !important;
        position: unset;
        top: initial;
        left: initial;
        right: initial;
        overflow: unset;
        transition: none;
    }

    &.active {
        height: calc(100vh - 67px);
        overflow-y: auto;
    }
/* Navigation */
    ul.navigation--list {
        grid-row-start: 1;
        padding-top: var(--spacing--lg);

        @media (--media--lg) {
            display: flex;
            align-items: stretch;
            padding: 0;
            margin-left: var(--spacing--md);
        }

        .navigation--listitem {
            list-style-type: none;
            display: block;
            padding: var(--spacing--sm) 0;



            @media (--media--lg) {
                display: inline-flex;
                align-items: flex-end;
                position: relative;
                padding: 0 var(--spacing--sm);
            }

            @media (--media--xl) {
                padding: 0 var(--spacing--md);
            }

            .navigation--listitem--link {
                display: inline-block;
                position: relative;
                z-index: 10;
                font-size: var(--headline--md);
                line-height: var(--headline--md-height);
                font-weight: 500;
                text-transform: uppercase;
                hyphens: auto;
                color: var(--color--light);
                cursor: pointer;
                transition: all 0.35s var(--easing--in-out);

                @media (--media--lg) {
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    padding: var(--spacing--sm) 0;
                    transition: color 0.35s var(--easing--in-out);
                }

                @media (hover: hover) {
                    &:hover {
                        color: var(--color--primary);

                        &::after {
                            height: 3px;
                            background: var(--color--primary);
                        }
                    }
                }

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 0;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: var(--color--font-base);
                    transition: height 0.35s var(--easing--in-out);
                }
            }

            &.current {
                .navigation--listitem--link {
                    color: var(--color--primary);

                    &::after {
                        height: 3px;
                        background: var(--color--primary);
                    }
                }
            }
        }
    }
}
