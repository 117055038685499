div.paragraph {
    display: block;
    width: 100%;
    hyphens: auto;
    word-break: break-word;
    gap: var(--spacing--column);
    columns: 1;

    @media (--media--md) {
        &.md\:columns-2 {
            columns: 2;
        }
    }
    @media (--media--lg) {
        &.lg\:columns-3 {
            columns: 3;
        }
        &.lg\:columns-4 {
            columns: 4;
        }
    }

    u {
        text-decoration: none;
        color: var(--color--primary);
    }

    &.fc--light {
        ul {
            li {
                &::before {
                    background: var(--color--light);
                }
            }
        }
    }

    ul {
        li {
            list-style-type: none;
            position: relative;
            padding-left: var(--spacing--xs);

            &::before {
                content: '';
                width: 5px;
                height: 5px;
                position: absolute;
                top: calc(var(--text--base) * 0.6);
                left: 0;
                background: var(--color--font-base);
                transform: rotate(-9deg);
            }
        }
    }

    ol {
		list-style-type: none;
		counter-reset: number;

        li {
            position: relative;
            padding-left: var(--spacing--md);
            margin-bottom: 0.25rem;

			&::before {
				content: ' ';
				width: var(--text--base);
				height: var(--text--base);
				position: absolute;
				top: 2px;
				left: 0;
                z-index: 1;
				font-size: 0.85rem;
				line-height: 16px;
                font-weight: 700;
				color: var(--color--primary);
                background: var(--color--primary);
				padding: 2px;
                transform: rotate(-9deg);
				text-align: center;
			}
			&::after {
				content: counter(number);
				width: var(--text--base);
				height: var(--text--base);
				position: absolute;
				top: 2px;
				left: 0;
                z-index: 1;
				font-size: 0.85rem;
				line-height: 16px;
                font-weight: 700;
				color: var(--color--light);
				padding: 2px;
				counter-increment: number;
				text-align: center;
			}

            ol {
                counter-reset: subnumber;

                li {
                    &::after {
                        content: counter(subnumber);
                        counter-increment: subnumber;
                    }
                }
            }

        }
    }

    a {
        display: inline-block;
        position: relative;
        text-decoration: underline;
        color: var(--color--primary);
        transition: all 0.35s var(--easing--in-out);
    }
}

.bg--primary {
    div.paragraph {
        a {
            color: var(--color--light);
        }
    }
}
