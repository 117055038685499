nav.navigation--footer {
    ul.navigation--list {
        li.navigation--listitem {
            list-style-type: none;
            display: block;
            line-height: var(--text--base-height);
            color: var(--color--light);
            margin-bottom: calc(var(--spacing--xs) * 0.5);

            a {
                display: inline-block;
                position: relative;
                font-size: var(--text--base);
                font-weight: 700;
                text-transform: uppercase;
                padding-bottom: calc(var(--spacing--xs) * 0.25);

                @media (hover:hover) {
                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                }

                &::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 2px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: var(--color--light);
                    transition: width 0.35s var(--easing--in-out);
                }
            }

            &.current, &.active {
                a {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }
}
