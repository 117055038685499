:root {
    /* colors */
    --color--primary: #ed6b69;
    --color--light: #fff;
    --color--grey: #eeedeb;
    --color--font-base: #625d55;

    /* typography */
    --font--base-family: Futura, sans-serif;
    --font--base-size: 18px;

    /* text-size*/
    /* xs */
    --text--xs: 0.75rem;
    --text--xs-height: 1.35;

    /* base */
    --text--base: 1rem;
    --text--base-height: 1.5;

    /* headline-size*/
    /* xs */
    --headline--xs: 1rem; /* 16px - 20px */
    --headline--xs-height: 1.35;
    /* sm */
    --headline--sm: 1.125rem; /* 18px - 22px */
    --headline--sm-height: 1.35;
    /* md */
    --headline--md: 1.25rem; /* 20px - 25px */
    --headline--md-height: 1.2;
    /* lg */
    --headline--lg: 1.55rem; /* 25px - 30px */
    --headline--lg-height: 1.25;
    /* xl */
    --headline--xl: 1.875rem; /* 30px - 36px */
    --headline--xl-height: 1.25;

    /* spacing */
    --spacing--xs: 0.95rem; /* 15px */
    --spacing--sm: 1.25rem; /* 20px */
    --spacing--md: 1.875rem; /* 30px */
    --spacing--lg: 4.375rem; /* 70px */
    --spacing--xl: 7.5rem; /* 120px */

    /* grid */
    --spacing--column: 1.25rem; /* 20px */

    /* easing */
    --easing--in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --easing--in: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --easing--out: cubic-bezier(0.075, 0.82, 0.165, 1);


    @media (--media--md) {
        /* headline-size*/
        /* xs */
        --headline--xs: 1.15rem; /* 20px */
        --headline--xs-height: 1.25;
        /* sm */
        --headline--sm: 1.375rem; /* 22px */
        --headline--sm-height: 1.25;
        /* md */
        --headline--md: 1.55rem; /* 25px */
        --headline--md-height: 1.2;
        /* lg */
        --headline--lg: 1.875rem; /* 30px */
        --headline--lg-height: 1.25;
        /* xl */
        --headline--xl: 2.25rem; /* 36px */
        --headline--xl-height: 1.25;

        /* grid */
        --spacing--column: 3.25rem;
    }
}

/* breakpoints*/

@custom-media --media--sm (min-width: 576px);
@custom-media --media--md (min-width: 768px);
@custom-media --media--lg (min-width: 992px);
@custom-media --media--xl (min-width: 1200px);
@custom-media --media--2xl (min-width: 1800px);
