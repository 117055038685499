/* background-colors */
.bg--primary {
	background: var(--color--primary);
}
.bg--light {
	background: var(--color--light);
}
.bg--grey {
	background: var(--color--grey);
}
.bg--dark {
	background: var(--color--font-base);
}

/* font-colors */
.fc--primary {
	color: var(--color--primary);
}
.fc--light {
	color: var(--color--light);
}
.fc--font-base {
    color: var(--color--font-base);
}
