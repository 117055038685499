div.headline {
	font-family: var(--font--base-family);

	h1, h2, h3, h4, h5, h6 {
		font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
		hyphens: auto;

        span:not(.nbsp, .shy) {
            display: block;
        }

        u {
            text-decoration: none;
            color: var(--color--primary);
        }
	}
}
