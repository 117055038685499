@font-face {
    font-style: normal;
    font-family: 'Futura';
    font-weight: 400;
    src: url('../fonts/Futura-Boo.eot');
    src: url('../fonts/Futura-Boo.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Futura-Boo.woff2') format('woff2'),
        url('../fonts/Futura-Boo.woff') format('woff'),
        url('../fonts/Futura-Boo.ttf') format('truetype'),
        url('../fonts/Futura-Boo.svg#Futura-Boo') format('svg');
    font-display: swap;
}

@font-face {
    font-style: normal;
    font-family: 'Futura';
    font-weight: 700;
    src: url('../fonts/Futura-Bol.eot');
    src: url('../fonts/Futura-Bol.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Futura-Bol.woff2') format('woff2'),
        url('../fonts/Futura-Bol.woff') format('woff'),
        url('../fonts/Futura-Bol.ttf') format('truetype'),
        url('../fonts/Futura-Bol.svg#Futura-Bol') format('svg');
    font-display: swap;
}
