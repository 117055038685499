@media (--media--lg) {
    div.text {
        div.headline--short {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &.headline--center {
                align-items: center !important;
            }

            h1, h2, h3, h4, h5, h6 {
                width: 75%;
            }
        }
    }
}

.history--text {
    div.paragraph {
        p {
            line-height: 2.25rem;

            strong {
                font-size: var(--headline--md);
                margin-right: 0.25rem;
                margin-left: 0.5rem;
            }
        }
    }
}
